import styled, { device } from "@styled";

export const SectionWrap = styled.section`
    position: relative;
    z-index: 1;
    padding:0 0 40px 0;
    ${device.small} {
        padding:20px 0 60px 0;
    }
    ${device.xlarge} {
        padding:20px 0 80px 0;
    }
`;
export const TranslateImgWrapper = styled.div`
    display:grid;
    align-items:center;
    gap: 25px;
    .translateImgCol{
        transition:all .5s ease-in-out;
        position:relative;
    }
    ${device.large} {
        grid-template-columns: repeat(3, 1fr);
        gap: 25px 15px;
        .translateImgCol{
            &:hover{
                transform:scale(.975);
            }
        }
    }
    ${device.xlarge} {
        gap:25px;
    }
    ${device.xxlarge} {
        gap: 30px 30px;
    }
`;
export const TranslateImgBoxWrapper = styled.div`
    display:grid;
    justify-content:center;
    gap:25px;
    &:nth-child(2){
        .translateImgCol{
            .textWrapper{
                bottom:30px;
            }
        }
    }
    ${device.medium}{
        grid-template-columns: repeat(2, 1fr);
    }
    ${device.large}{
        grid-template-columns: repeat(1, 1fr);
    }
`;

export const TranslateImgBox = styled.div`
    img{
        max-width:100%;
    }
`;
export const TranslateText = styled.div`
    position:absolute;
    bottom:35px;
    left:50%;
    transform:translateX(-50%);
    width:90%;
    ${device.xlarge} {
        bottom:50px;
    }
    h4{
        font-size: 20px;
        text-align:center;
        letter-spacing: 0px;
        line-height: 1.333;
        color: #ffffff;
        font-weight: 700;
        margin:0;
        ${device.xlarge} {
            font-size: 24px;
        }
    }
    .desc{
        font-size:15px;
        color:#fff;
        text-align:center;
        line-height:1.2667;
        margin-top:15px;
    }
`;
