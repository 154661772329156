import React from "react"
import PropTypes from "prop-types";
import { Container} from "@ui/wrapper";
import SectionTitle from "@ui/section-title";
import Anchor from "@ui/anchor";
import Image from "@ui/image";
import Heading from "@ui/heading";
import Text from "@ui/text";
import { SectionWrap, TranslateImgWrapper, TranslateImgBoxWrapper, TranslateImgBox, TranslateText} from "./style";
const TranslateImageBox = ({data}) => {
    return(
        <SectionWrap id={data.section}>
            <Container>
                <SectionTitle
                    title={data.section_title?.title}
                    subtitle={data.section_title?.subtitle}
                />
                <TranslateImgWrapper>
                    {/* First Column */}
                    <TranslateImgBoxWrapper>
                        {data.items.slice(0, 3).map((el, index) => (
                            <Anchor path={el.path} key={index} className="translateImgCol" title={el.title}>
                                <TranslateImgBox>
                                    <Image
                                        src={el.images[0]?.src}
                                        alt={el.images[0]?.alt || "Image"}
                                        title={el.images[0]?.alt || "Image"}
                                    />
                                </TranslateImgBox>
                                <TranslateText className="textWrapper">
                                    <Heading as="h4">{el.title}</Heading>
                                    {el.description && <Text as="p" className="desc">{el.description}</Text>}
                                </TranslateText>
                            </Anchor>
                        ))}
                    </TranslateImgBoxWrapper>
                    {/* Second Column */}
                    <TranslateImgBoxWrapper>
                        {data.items.slice(3, 5).map((el, index) => (
                            <Anchor path={el.path} key={index} className="translateImgCol" title={el.title}>
                                <TranslateImgBox>
                                    <Image
                                        src={el.images[0]?.src}
                                        alt={el.images[0]?.alt || "Image"}
                                        title={el.images[0]?.alt || "Image"}
                                    />
                                </TranslateImgBox>
                                <TranslateText className="textWrapper">
                                    <Heading as="h4">{el.title}</Heading>
                                    {el.description && <Text as="p" className="desc">{el.description}</Text>}
                                </TranslateText>
                            </Anchor>
                        ))}
                    </TranslateImgBoxWrapper>
                    <TranslateImgBoxWrapper>
                        {data.items.slice(5, 8).map((el, index) => (
                            <Anchor path={el.path} key={index} className="translateImgCol"  title={el.title}>
                                <TranslateImgBox>
                                    <Image
                                        src={el.images[0]?.src}
                                        alt={el.images[0]?.alt || "Image"}
                                        title={el.images[0]?.alt || "Image"}
                                    />
                                </TranslateImgBox>
                                <TranslateText className="textWrapper">
                                    <Heading as="h4">{el.title}</Heading>
                                    {el.description && <Text as="p" className="desc">{el.description}</Text>}
                                </TranslateText>
                            </Anchor>
                        ))}
                    </TranslateImgBoxWrapper>
                </TranslateImgWrapper>
            </Container>
         </SectionWrap>
    );
};
TranslateImageBox.propTypes = {
    items: PropTypes.object,
};
export default TranslateImageBox;